<template>
  <div class="flex-1 px-4 py-2 bg-gray-100">
    <h1 class="text-xl font-bold" v-text="title"></h1>

    <div class="block" v-if="!curr">
      <select v-model="currId" class="px-4 py-2 mx-2 my-2" @click.prevent >
        <option value>Please select...</option>
        <option v-for="el in data" :key="el.id" :value="el.id">{{ el[fields[0]] }}</option>
      </select>
      <button class="ml-5 btn btn-primary" @click="add">Add New</button>
    </div>

    <form v-if="curr" @submit.prevent="save" class="mt-2">
      <div v-for="el in fields" :key="el">
        <label class="block mt-2 font-bold" v-text="el"></label>
        <input
          v-if="!isText(el)"
          v-model="curr[el]"
          type="text"
          class="block w-9/12 p-2"
        />

        <textarea
          v-if="isText(el)"
          v-model="curr[el]"
          rows="6"
          class="w-9/12 p-2"
        ></textarea>
      </div>

      <div class="block">
        <button class="float-right btn btn-primary" @click="remove">Delete</button>
        <button type="submit" class="mt-3 bg-green-300 btn">Save</button>
        <a href="#" class="mt-3 ml-5 text-red-600" @click.prevent="currId=''">Cancel</a>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["title", "fields", "data", "collection"],
  data() {
    return {
      currId: "",
      curr: null,
    };
  },
  computed: {
     
  },
  watch: {
    currId(key) {
      this.curr = this.data.find((x) => x.id == key);
    },
    collection() {
        this.currId="";
        this.curr = null;
    }
  },
  methods: {
       isText(el) {
          return (el=='Description' || el=='answer' || el=='Evaluation' || el=='Tip' );
      },
    add() {
      this.curr = {
        id: null,
        createdAt: new Date(),
      };
      this.fields.forEach((el) => {
        this.curr[el] = "";
      });
    },
    save() {
      var x = this.curr.Order;
      if (x) {
        this.curr.Order = parseInt(x);    
      }
      x = this.curr.order;
      if (x) {
        this.curr.order = parseInt(x);    
      }
      this.curr.updatedAt = new Date();
      x = this.curr.Points;
      if (x) {
          if (typeof(x)=='object') {
              this.curr.Points = JSON.stringify(x).replace('[','').replace(']','');
          }
          this.curr.Points = this.curr.Points.split(',');
          this.curr.Points.forEach((part, index, theArray) => {
              theArray[index] = parseInt(part);
          });
      }
      if (this.curr.id) {
          console.log(this.curr);
        this.collection
          .doc(this.curr.id)
          .update(this.curr)
          .then(() => {
            this.currId = "";
          })
          .catch((error) => {
            console.error("Error updating document text: ", error);
          });
      } else {
        this.collection
          .add(this.curr)
          .then(() => {
            this.currId = "";
            this.curr = null;
          })
          .catch((error) => {
            console.error("Error updating document text: ", error);
          });
      }
    },
    remove() {
           if (confirm("Do you really want to delete this record?"))
        this.collection
          .doc(this.curr.id)
          .delete()
          .then(() => {
            this.currId = "";
          })
          .catch(error => {
            console.error("Error updating document text: ", error);
          });
    },
  },
};
</script>

<style>
</style>