<template>
  <div class="bg-white text-black">
    <div v-if="!user">
      <div
        class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      >
        <div class="max-w-md w-full">
          <div>
            <!-- <img class="mx-auto h-12 w-auto" src="" alt="Administration Signin" /> -->
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">Sign in</h2>
          </div>
          <form class="mt-8" @submit.prevent="login">
            <input type="hidden" name="remember" value="true" />
            <div class="rounded-md shadow-sm">
              <div>
                <input
                  aria-label="Email address"
                  name="email"
                  type="email"
                  v-model="email"
                  required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                  placeholder="Email address"
                />
              </div>
              <div class="-mt-px">
                <input
                  aria-label="Password"
                  name="password"
                  type="password"
                  v-model="pass"
                  required
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                  placeholder="Password"
                />
              </div>
            </div>
            <div class="text-red-600 text-sm" v-html="error"></div>
            <div class="text-green-600 text-sm" v-html="status"></div>

            <div class="mt-6 flex items-center justify-between">
              <!-- <div class="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
              />
              <label
                for="remember_me"
                class="ml-2 block text-sm leading-5 text-gray-900"
              >Remember me</label>
              </div>-->

              <div class="text-sm leading-5">
                <a
                  href="#"
                  @click.prevent="forgotPW"
                  :disabled="!email"
                  class="font-medium text-primary hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >Forgot your password?</a>
              </div>
            </div>

            <div class="mt-6">
              <button
                type="submit"
                class="btn btn-primary"
                oldclass="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path fill-rule="evenodd" clip-rule="evenodd" />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container mx-auto mt-5">
        <div class="clearfix">
          <a href="#" @click.prevent="logout" class="btn btn-primary float-right">Log out</a>
        </div>
        <div class="flex height-100 width-100">
          <div class="bg-gray-200 px-4 py-2 flex-initial">
            <ul>
              <li>
                <a
                  href="#"
                  @click.prevent="editConditions"
                  class="text-red-600 hover:text-red-700"
                  :class="conditionsPanel ? 'font-bold':''"
                >Conditions</a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="editSplints"
                  class="text-red-600 hover:text-red-700"
                  :class="splintsPanel ? 'font-bold':''"
                >Splints</a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="editPainpoints"
                  class="text-red-600 hover:text-red-700"
                  :class="painPointsPanel ? 'font-bold':''"
                >Pain Points</a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="editProducts"
                  class="text-red-600 hover:text-red-700"
                  :class="productsPanel ? 'font-bold':''"
                >Products</a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="editFaqs"
                  class="text-red-600 hover:text-red-700"
                  :class="faqsPanel ? 'font-bold':''"
                >FAQs</a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="showContacts"
                  class="text-red-600 hover:text-red-700"
                  :class="contactsPanel ? 'font-bold':''"
                >Contacts</a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="showUpload"
                  class="text-red-600 hover:text-red-700"
                  :class="uploadPanel ? 'font-bold':''"
                >Uploads</a>
              </li>
              <li class="my-4">
                <a
                  href="#"
                  id="link"
                  :class="downloadOk ? 'btn text-white bg-red-700':'hidden'"
                >Backup</a>
              </li>
            </ul>
          </div>
          <!-- Conditions -->
          <edit-admin
            v-if="conditionsPanel"
            title="Conditions"
            :fields="['Condition','ani','Choice','Alt_1','Alt_2','Description','Evaluation','Order']"
            :data="conditions"
            :collection="conditionsProp"
          />

          <!-- Splints  -->
          <edit-admin
            v-if="splintsPanel"
            title="Splints"
            :fields="['Splint','Type','ani','Choice','Alt_1','Alt_2','Description','Evaluation','Order']"
            :data="splints"
            :collection="splintsProp"
          />
          <!-- Pain Points  -->
          <edit-admin
            v-if="painPointsPanel"
            title="Pain Points"
            :fields="['Points','Choice','Alt_1','Alt_2','Tip','Evaluation','Condition','Splint']"
            :data="painPoints"
            :collection="painPointsProp"
          />

          <!-- Products -->
          <!-- <div v-if="productsPanel" class="bg-gray-100 px-4 py-2 flex-1">
            <h1 class="font-bold text-xl">Products</h1>
          </div>-->
          <edit-admin
            v-if="productsPanel"
            title="Products"
            :fields="['Name','IDX','Affilate_URL','Colors','Comfort','Construction','Description','Durability','Ease_don_doff','Photo_1','Photo_2','Photo_3','Price','Rating','Style','URL','Value','Versatility','Washability']"
            :data="products"
            :collection="productsProp"
          />

          <!-- FAQS -->
          <edit-admin
            v-if="faqsPanel"
            title="FAQs"
            :fields="['question','order','answer']"
            :data="faqs"
            :collection="faqsProp"
          />

          <!-- Contacts -->
          <div v-if="contactsPanel" class="bg-gray-100 px-4 py-2 flex-1">
            <h1 class="font-bold text-xl">Contacts</h1>
            <div
              v-for="contact in contacts"
              :key="contact.id"
              class="pt-3 border-gray-900 mt-3 border-t"
            >
              <div>
                <b>Name</b>
                : {{ contact.name }}
              </div>
              <div>
                <b>Email</b>
                : {{ contact.email }}
              </div>
              <div>
                <b>Subject</b>
                : {{ contact.subject }}
              </div>
              <div>
                <b>Message</b>
                : {{ contact.message }}
              </div>
              <div class="mb-3">
                <b>Created</b>
                : {{ fullDate(contact.createdAt) }}
              </div>
              <div>
                <b>Product</b>
                : {{ contact.product_reviews }}
              </div>
              <div>
                <b>other</b>
                : {{ contact.other }}
              </div>
            </div>
          </div>

          <div v-if="uploadPanel" class="bg-gray-100 px-4 py-2 flex-1">
            <h1 class="font-bold text-xl">Uploads</h1>
            <ul>
              <li
                v-for="pic in picList"
                :key="pic.name"
                class="border border-black my-5 p-3 bg-white"
              >
                <img :src="pic.url" class="float-left" style="max-height:100px" />
                <label for v-html="pic.name" class="text-lg ml-4 font-bold"></label>
                <input :value="pic.url" class="w-full" readonly />
                <!-- <button class="btn btn-warning" @click="showPic(pic.name)">{{ pic.name }}</button> -->
              </li>
            </ul>
            <div class="bg-gray-400 p-3">
              <input type="file" @change="previewImage" accept="image/*" class="btn" ref="fileinp" />
              <p>
                Progress: {{uploadValue.toFixed()+"%"}}
                <progress
                  id="progress"
                  :value="uploadValue"
                  max="100"
                ></progress>
              </p>
              <p class="font-bold">Preview</p>
              <div v-if="picture!=null">
                <img class="preview" :src="picture" />
                <br />
                <button @click="onUpload" class="btn btn-primary">Upload</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  auth,
  storage,
  conditionsCollection,
  productsCollection,
  splintsCollection,
  painPointsCollection,
  faqsCollection,
  contactsCollection,
} from "../firebase";
import EditAdmin from "../components/EditAdmin.vue";

export default {
  name: "admin",
  components: {
    "edit-admin": EditAdmin,
  },
  firestore() {
    return {
      conditions: conditionsCollection.orderBy("Order"),
      splints: splintsCollection.orderBy("Order"),
      products: productsCollection.orderBy("Name", "asc"),
      faqs: faqsCollection.orderBy("order"),
      contactList: contactsCollection.orderBy("createdAt"),
      painPoints: painPointsCollection.orderBy("createdAt"),
    };
  },
  data() {
    return {
      email: "",
      pass: "",
      user: null,
      error: "",
      status: "",

      conditions: [],
      conditionsPanel: true,

      splints: [],
      splintsPanel: false,

      painPoints: [],
      painPointsPanel: false,

      products: [],
      productsPanel: false,

      faqs: [],
      faqsPanel: false,

      contacts: [],
      currContactId: "",
      contactsPanel: false,
      uploadPanel: false,
      currContact: null,

      imageData: null,
      picture: null,
      uploadValue: 0,
      picList: [],
      download: [],
      downloadOk: false,
    };
  },
  computed: {
    painPointsProp() {
      return painPointsCollection;
    },
    splintsProp() {
      return splintsCollection;
    },
    productsProp() {
      return productsCollection;
    },
    faqsProp() {
      return faqsCollection;
    },
    conditionsProp() {
      return conditionsCollection;
    },
    contactsProp() {
      return contactsCollection;
    },
  },

  methods: {
    fullDate(today) {
      return new Date(today.seconds * 1000);
    },
    closePanels() {
      this.productsPanel = false;
      this.conditionsPanel = false;
      this.splintsPanel = false;
      this.painPointsPanel = false;
      this.faqsPanel = false;
      this.contactsPanel = false;
      this.uploadPanel = false;
    },
    editConditions() {
      this.closePanels();
      this.conditionsPanel = true;
    },
    editSplints() {
      this.closePanels();
      this.splintsPanel = true;
    },
    editPainpoints() {
      this.closePanels();
      this.painPointsPanel = true;
    },
    editProducts() {
      this.closePanels();
      this.productsPanel = true;
    },
    editFaqs() {
      this.closePanels();
      this.faqsPanel = true;
    },
    showContacts() {
      this.closePanels();
      this.contactsPanel = true;
    },
    showUpload() {
      this.closePanels();
      this.uploadPanel = true;
    },

    clearStatus() {
      this.error = "";
      this.status = "";
    },
    login() {
      this.clearStatus();
      auth
        .signInWithEmailAndPassword(this.email, this.pass)
        .then((user) => {
          window.user = this.user = user;
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
    logout() {
      this.clearStatus();
      auth.signOut().catch((error) => {
        // An error happened.
        this.error = error.message;
      });
      this.user = window.user = null;
    },
    forgotPW() {
      this.clearStatus();
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.status =
            "Password Reset Email send to " +
            this.email +
            ".<br>Please check your inbox.";
        })
        .catch((error) => {
          this.error = error.message;
        });
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.imageData = event.target.files[0];
      this.picture = window.URL.createObjectURL(this.imageData);
    },

    showPic(pic) {
      //   console.log(pic);
      storage
        .ref()
        .child(pic)
        .getDownloadURL()
        .then((url) => {
          this.picture = url;
          //   console.log(url);
        });
    },
    onUpload() {
      this.picture = null;
      const storageRef = storage
        .ref(`${this.imageData.name}`)
        .put(this.imageData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          //   storageRef.snapshot.ref.getDownloadURL().then((url) => {
          //     this.picture = url;
          //   });
          this.listStorage();
          this.picture = null;
          this.$refs.fileinp.value = null;
        }
      );
    },

    listStorage() {
      // Create a reference under which you want to list
      var listRef = storage.ref();
      this.picList = [];

      // Find all the prefixes and items.
      listRef
        .listAll()
        .then((res) => {
          res.items.forEach((itemRef) => {
            storage
              .ref()
              .child(itemRef.name)
              .getDownloadURL()
              .then((url) => {
                this.picList.push({ name: itemRef.name, url: url });
                //   console.log(url);
              });
          });
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    exportJson() {
      conditionsCollection.get().then((snap) => {
        this.download.conditions = snap.docs.map((doc) => doc.data());
        splintsCollection.get().then((snap) => {
          this.download.splints = snap.docs.map((doc) => doc.data());
          productsCollection.get().then((snap) => {
            this.download.products = snap.docs.map((doc) => doc.data());
            painPointsCollection.get().then((snap) => {
              this.download.painPoints = snap.docs.map((doc) => doc.data());
              faqsCollection.get().then((snap) => {
                this.download.faqs = snap.docs.map((doc) => doc.data());
                var json = JSON.stringify({
                  conditions: this.download.conditions,
                  splints: this.download.splints,
                  faqs: this.download.faqs,
                  products: this.download.products,
                  painPoints: this.download.painPoints,
                });
                let el = document.getElementById("link");
                var blob = new Blob([json], { type: "application/json" });
                var url = URL.createObjectURL(blob);
                el.setAttribute("href", url);
                el.setAttribute("download", "backup.json");
                this.downloadOk = true;
              });
            });
          });
        });
      });
    },
  },
  mounted() {
    this.user = window.user;
    this.listStorage();
    this.contacts = this.contactList;
    // this.conditions.forEach((el) => {
    //   console.log("c", el);
    // });
    this.exportJson();
  },
};
</script>

<style scoped="">
img.preview {
  width: 200px;
}
</style>